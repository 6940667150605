a {
	color: inherit;
	text-decoration: none;
}

p {
	line-height: 1.5;
}

h1 {
	font-size: 64px;
}

h3 {
	font-size: 48px;
	margin-top: 0;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 20px;
	margin-bottom: 20px;
}

li {
	font-size: 16px;
	list-style: none;
}

.red {
	color: #dc143c;
}

.grey {
	color: #c6c9ca;
}

.white {
	color: #ffffff;
}

.bold {
	font-weight: 700;
}

button {
	font-size: 16px;
	background-color: #dc143c;
	color: #fff;
	border-radius: 50px;
	height: 55px;
	width: 140px;
	border-style: none;
}

button:hover {
	cursor: pointer;
	background-color: #ca1035;
}

.description-text {
	font-size: 16px;
}

.why-choose-us-container,
.digital-calculator {
	background-color: #fafbfb;
	padding: 200px 0;
}

.info-card-heading h3,
.pricing h3 {
	margin: 0;
}

.info-card-heading {
	margin-bottom: 90px;
}

/* disable number input scroll */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.pricing,
.for-better-future-container {
	padding: 200px 0;
}

.footer {
	padding-top: 200px;
	padding-bottom: 50px;
}

.max-width-18 {
	max-width: 1800px;
	margin: auto;
}

.max-width-20 {
	max-width: 2000px;
	margin: auto;
}

.animate {
	opacity: 0;
	transform: translateY(100px);
	transition: 0.4s ease-out;
	transition-delay: 1s;
}

.animate.active {
	opacity: 1;
	transform: translateY(0px);
}

@media screen and (max-width: 1800px) {
	h1 {
		font-size: 62px;
	}
}

@media screen and (max-width: 1350px) {
	h1 {
		font-size: 50px;
	}
}

@media screen and (max-width: 900px) {
	h1 {
		font-size: 45px;
	}

	.info-card-heading {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 700px) {
	h1 {
		font-size: 40px;
	}

	h3 {
		font-size: 32px;
	}

	h4 {
		font-size: 20px;
	}

	.info-card-heading {
		margin-bottom: 60px;
	}

	.pricing,
	.digital-calculator,
	.why-choose-us-container,
	.for-better-future-container {
		padding: 100px 0;
	}
	.footer {
		padding-top: 100px;
	}
}

.about-us {
	padding: 100px 0;
}

.about-us__heading-container {
	background-color: #f6f6f7;
	padding: 80px;
	border-radius: 100px;
}

.about-us__heading-container__top-section {
	display: flex;
	padding-bottom: 40px;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e1e3e4;
}

.about-us__heading-container__top-section > * {
	margin: 0;
}

.about-us__heading-container__top-section p {
	font-size: 20px;
}

.about-us__heading-container__top-section .empower {
	max-width: 325px;
}

.about-us__heading-container__image {
	margin-top: 40px;
	width: 100%;
	height: 100%;
}

.about-us-who-what {
	margin-top: 200px;
	border-top: 0.1px solid #c6c9ca;
}

.about-us-who-what__details {
	margin-left: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 800px;
}

.about-us-who-what__details__card {
	width: 345px;
	margin-bottom: 50px;
}

.about-us-quote {
	display: flex;
	align-items: center;
	margin-left: auto;
	width: 800px;
}

.about-us-quote__text {
	font-size: 32px;
	margin-left: 50px;
	margin-top: 90px;
	text-underline-offset: 8px;
	max-width: 450px;
}

.about-us__circle {
	position: relative;
	width: 125px;
}

.about-us__circle .about-us__logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.about-us__circle .about-us__name {
	animation: rotate 20s linear 0s infinite forwards;
}

@keyframes rotate {
	0% {
		transform: rotate(0turn);
	}
	100% {
		transform: rotate(1turn);
	}
}

@media screen and (max-width: 1100px) {
	.about-us__heading-container__top-section {
		display: block;
	}

	.about-us__heading-container__top-section > *:not(:last-child) {
		margin-bottom: 30px;
	}

	.about-us__heading-container__top-section .empower {
		max-width: unset;
	}

	.about-us__heading-container {
		padding: 40px;
		border-radius: 48px;
	}

	.about-us-who-what {
		margin-top: 150px;
	}
}

@media screen and (max-width: 900px) {
	.about-us-who-what__details {
		flex-direction: column;
	}

	.about-us-who-what {
		margin-top: 100px;
	}

	.about-us-who-what__details :last-child {
		margin-bottom: 0px;
	}

	.about-us-who-what__details,
	.about-us-quote {
		width: unset;
	}

	.about-us-who-what__details__card {
		width: unset;
	}
}

@media screen and (max-width: 700px) {
	.about-us-quote {
		margin-top: 80px;
		flex-direction: column;
	}

	.about-us-quote img {
		align-self: baseline;
	}

	.about-us-quote__text {
		margin-left: unset;
		margin-top: 30px;
	}

	.about-us__heading-container {
		border-radius: 30px;
	}
}

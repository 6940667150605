.info-cards {
	display: flex;
	justify-content: space-between;
}

.info-card {
	background-color: #fff;
	width: 275px;
	border-radius: 15px;
	padding: 30px;
	transition: 0.15s linear;
}

.info-card:hover {
	transform: scale(1.03);
	box-shadow: 0px 0px 30px 0px rgba(142, 152, 168, 0.2);
}

.info-card:not(:last-child) {
	margin-right: 1.2rem;
}

.info-card__heading {
	font-size: 20px;
}
.info-card__description {
	font-size: 14px;
}

.info-card-stretched {
	width: 524px;
}

.info-card-stretched .info-card__main {
	display: flex;
}

.info-card-stretched .info-card__additional-icons {
	margin-top: 50px;
}

.info-card-stretched .info-card__additional-icons .info-card__icon {
	height: 48px;
	width: auto;
}
.info-card-stretched .info-card__main .info-card__icon,
.info-card-stretched .info-card__additional-icons :not(:last-child) {
	margin-right: 20px;
}

.info-card-stretched .info-card__heading {
	margin-top: 0;
}

@media screen and (max-width: 1100px) {
	.info-cards {
		flex-direction: column;
		align-items: center;
	}
	.info-card {
		width: 80%;
	}

	.info-card:not(:last-child) {
		margin-right: unset;
		margin-bottom: 1.2rem;
	}
}

@media screen and (max-width: 700px) {
	.why-choose-us {
		text-align: center;
	}

	.info-card-stretched .info-card__main {
		display: block;
	}

	.info-card-stretched .info-card__main .info-card__icon {
		margin-right: unset;
	}

	.info-card-stretched .info-card__additional-icons .info-card__icon {
		height: 30px;
	}
	.for-better-future .info-cards .info-cards__section-one,
	.for-better-future .info-cards .info-cards__section-two {
		width: 100%;
	}
}

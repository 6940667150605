.navbar {
	padding: 50px 0;
}

.nav__container {
	max-width: 1620px;
	width: 90%;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.navbar .logo {
	margin-right: 20px;
}

.navbar__list {
	display: flex;
	flex: 1;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	max-width: 767px;
}

.navbar__list li a {
	padding: 5px;
	display: flex;
	padding: 8px 16px;
	cursor: pointer;
	position: relative;
}

.navbar__list li a:hover,
.navbar__list li a.active {
	color: #dc143c;
}
.navbar__list li a.active .indicator {
	display: block;
}

.navbar__list li a .indicator {
	position: absolute;
	top: calc(100% + 8px);
	display: none;
}

@media screen and (max-width: 900px) {
	.navbar {
		padding: 20px 0;
	}

	.logo img {
		height: 44px;
	}

	.navbar__list {
		flex: unset;
	}

	.navbar__list li:not(:last-child) {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	.logo img {
		height: 28px;
	}

	.navbar__list li:not(:last-child) {
		display: none;
	}
	.navbar__list li a .indicator {
		display: none !important;
	}
}

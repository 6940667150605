.for-better-future {
	display: flex;
	gap: 170px;
}

.for-better-future .info-cards {
	gap: 30px;
	justify-content: flex-end;
}

.for-better-future .info-cards .info-card {
	margin: 0;
	min-width: 310px;
	background: #fafbfb;
}

.for-better-future .info-cards .info-cards__section-one,
.for-better-future .info-cards .info-cards__section-two {
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 50%;
}

.for-better-future__left-section {
	flex: 1;
	min-width: 500px;
}

.for-better-future__left-desc {
	line-height: 1.5;
}

.for-better-future__left-desc li {
	list-style: disc;
}

.for-better-future__right-section {
	flex: 1;
}

.for-better-future__right-section .info-card {
	width: 200px;
	height: 230px;
	margin-bottom: 1.2rem;
}

.info-cards__section-two {
	margin-top: 50px;
}

.for-better-future__indicator {
	width: 128px;
	margin-top: 50px;
}

@media screen and (max-width: 1800px) {
	.for-better-future__left-desc {
		max-width: 500px;
	}
}

@media screen and (max-width: 1350px) {
	.for-better-future__left-desc {
		max-width: unset;
	}

	.for-better-future {
		flex-direction: column;
	}

	.for-better-future__right-section .info-cards {
		justify-content: center;
	}

	.info-cards__section-two {
		margin-top: unset;
	}

	.for-better-future__right-section .info-card {
		width: 400px;
	}
}

@media screen and (max-width: 1100px) {
	.for-better-future__right-section .info-card {
		width: unset;
	}
}

@media screen and (max-width: 700px) {
	.for-better-future__left-section {
		min-width: unset;
	}

	.for-better-future .info-card-heading {
		text-align: center;
	}

	.for-better-future__right-section {
		margin-top: 0px;
	}

	.for-better-future {
		gap: 60px;
	}
	.for-better-future__right-section .info-card {
		height: 100%;
	}
}

.why-choose-us__top-section {
	display: flex;
}

.why-choose-us__top-section .info-card-heading {
	flex: 1;
}

.why-choose-us__top-section .description-text {
	margin-top: 60px;
	flex: 0.5;
}

@media screen and (max-width: 900px) {
	.why-choose-us__top-section {
		flex-direction: column;
	}

	.why-choose-us__top-section .description-text {
		margin: 30px 0;
	}
}

@media screen and (max-width: 700px) {
	.why-choose-us {
		text-align: center;
	}
	.why-choose-us__top-section .description-text {
		margin-top: 0;
	}
}

.hero {
	padding: 98px 0;
}

.hero__flex {
	display: flex;
}

.hero__maintext {
	flex: 1;
	margin-top: 70px;
}

.hero__maintext__icon {
	height: 50px;
}

.hero__flex p {
	font-size: 24px;
	margin: 50px 0;
	white-space: pre-wrap;
}

.hero__maintext .gradient {
	background: linear-gradient(143deg, #dc3535 0%, rgba(220, 53, 53, 0) 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.hero__button {
	font-size: 20px;
	width: 155px;
}

.hover-svg {
	flex: 0.2;
	width: 100%;
	overflow: visible;
}

@media screen and (max-width: 1800px) {
	.hero__maintext p {
		font-size: 22px;
	}

	.hover-svg {
		height: 500px;
	}
}

@media screen and (max-width: 1500px) {
	.hover-svg {
		height: 400px;
	}

	.hero__maintext {
		margin-top: 30px;
	}
}

@media screen and (max-width: 1350px) {
	.hero {
		padding-top: 35px;
	}

	.hero__maintext__icon {
		height: 40px;
	}

	.hero__maintext p {
		font-size: 20px;
		margin: 30px 0;
	}
}

@media screen and (max-width: 1100px) {
	.hover-svg {
		height: 300px;
	}
}

@media screen and (max-width: 900px) {
	.hero {
		padding-top: 25px;
	}
	.hero__flex {
		flex-direction: column;
	}

	.hero__maintext {
		margin-top: 10px;
	}

	.hero__maintext__icon {
		height: 35px;
	}

	.hero__maintext p {
		font-size: 18px;
		margin: 30px 0;
	}

	.hero__button {
		font-size: 16px;
		width: 130px;
	}
}

@media screen and (max-width: 700px) {
	.hero {
		padding-top: 0;
	}

	.hero__maintext p {
		font-size: 16px;
		margin: 20px 0;
	}

	.hover-svg {
		margin-top: 50px;
	}
}

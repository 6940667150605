.text-field {
	border: none;
	height: 120px;
}

.text-field__label {
	margin: 15px 0;
}

.text-field__input-container {
	position: relative;
}

.text-field__input-container__number-input {
	border: 1px solid #e1e3e4;
	padding: 7px 15px;
	border-radius: 50px;
	outline: none;
	width: 382px;
	height: 54px;
	box-sizing: border-box;
	padding-left: 45px;
	background-color: #f6f6f7;
	font-size: 20px;
	letter-spacing: 1px;
}

.text-field__currency {
	position: absolute;
	top: 14px;
	left: 22px;
	font-size: 20px;
}

@media screen and (max-width: 500px) {
	.text-field__input-container__number-input {
		width: unset;
	}
}

.footer-container {
	background-color: #1b262c;
	color: #c6c9ca;
}

.footer__upper-content {
	display: flex;
	/* justify-content: space-between; */
	border-bottom: 1px solid #343e43;
	padding-bottom: 30px;
	gap: 60px;
}

.footer__upper-content__company .logo {
	margin-bottom: 40px;
}

.footer__upper-content > * {
	flex-grow: 1;
	width: 30;
	min-width: 243px;
}

footer .footer__upper-content__company {
	max-width: 600px;
	flex-grow: 2;
}

.footer__upper-content__opening-hours .bold,
.footer__upper-content__contact-us .bold {
	margin-top: 0;
}

.footer__upper-content__contact-us__information li {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.footer__upper-content__contact-us__information li span {
	margin-left: 10px;
}

.footer__lower-content__first {
	display: flex;
	align-items: baseline;
}

.footer__lower-content__first__address,
.footer__lower-content__second__privacy {
	margin-top: 30px;
}

.footer__lower-content__first__social {
	align-items: center;
	display: flex;
	margin-left: auto;
	justify-content: space-between;
	width: 200px;
}

.footer__lower-content__second__privacy a:hover {
	border-bottom: 1px solid #e1e3e4;
}

.footer__bottom {
	height: 72px;
	width: 100%;
	background-repeat: repeat;
}

@media screen and (max-width: 1350px) {
	.footer__upper-content {
		flex-wrap: wrap;
	}

	.footer__upper-content__company {
		flex: 0 0 100%;
	}

	.footer__upper-content > * {
		max-width: unset;
	}

	.footer__upper-content__opening-hours .bold,
	.footer__upper-content__contact-us .bold {
		margin-top: 20px;
	}

	.footer__lower-content__first {
		flex-direction: column;
	}

	.footer__lower-content__first__social {
		margin-left: unset;
	}
}

@media screen and (max-width: 700px) {
	.footer__upper-content {
		flex-direction: column;
	}

	.footer__upper-content__company .logo img {
		height: unset;
	}
}

.pricing {
	padding: 0 20px;
	max-width: 1800px;
	margin: auto;
}

.pricing__heading {
	text-align: center;
}

.pricing__note {
	margin-top: 150px;
	text-align: center;
}

.pricing__cards-container {
	display: flex;
	justify-content: center;
	margin: 60px 0;
	gap: 30px;
	align-items: flex-start;
}

.pricing__cards-container__card {
	position: relative;
	border-radius: 20px;
	height: 850px;
	width: 100%;
	max-width: 500px;
	padding: 30px;
	margin-top: 30px;
}

.pricing__cards-container__card:hover {
	border-color: #dc143c;
}

.pricing__cards-container__card--primary {
	border: 1px solid #a27d1d;
}

.pricing__cards-container__card--secondary {
	border: 1px solid #619e30;
}

.pricing__cards-container__card button {
	position: absolute;
	bottom: 50px;
}

.pricing__cards-container__card__cover {
	margin: 50px 0;
}

.pricing__cards-container__card__cover li {
	margin: 30px 0;
	display: flex;
	align-items: center;
}

.pricing__cards-container__card__cover li span {
	margin-left: 10px;
	color: #767d80;
	width: 200px;
}
.pricing__cards-container__card__section__heading,
.pricing__cards-container__card__section__description {
	color: #343e43;
	margin: 0;
	margin-bottom: 40px;
}

.pricing__cards-container__card__section__price-suffix {
	color: #343e43;
	font-size: 14px;
	font-weight: 400;
	margin-left: 5px;
}

.pricing__cards-container__card__section__heading {
	font-size: 20px;
	min-height: 60px;
}

.pricing__cards-container__card__section {
	top: 180px;
	max-width: 250px;
}

.pricing__cards-container__card__section__price--primary {
	color: #a27d1d;
}

.pricing__cards-container__card__section__price--secondary {
	color: #619e30;
}

.pricing__cards-container__card__section__note {
	margin-top: 40px;
	font-size: 12px;
	color: #5d6569;
}

.pricing__cards-container__card .secondary {
	background-color: white;
	border: 1px solid #619e30;
	color: #619e30;
}

.pricing__cards-container__card .primary {
	background-color: #a27d1d;
}

@media screen and (max-width: 1350px) {
	.pricing__cards-container {
		margin: 0 50px;
		flex-wrap: wrap;
	}

	.pricing__cards-container__card__section {
		max-width: none;
	}

	.pricing__cards-container__card__cover li span {
		width: auto;
	}
}

@media screen and (max-width: 1100px) {
	.pricing__cards-container {
		flex-direction: column;
		align-items: center;
		flex-wrap: unset;
	}
}

@media screen and (max-width: 700px) {
	.pricing__cards-container__card__cover li span {
		width: 200px;
	}
}

.digital-calculator h3 {
	text-align: center;
}

.digital-calculator__interactive {
	margin: auto;
	max-width: 1000px;
}

.digital-calculator__interactive-note {
	text-align: center;
	margin: 50px auto;
	color: #848a8d;
	font-size: 14px;
}

.digital-calculator__options {
	padding: 5px;
	display: flex;
	align-items: center;
	color: #c6c9ca;
	justify-content: center;
	margin-bottom: 60px;
}

.digital-calculator__options li {
	margin: 10px;
	padding: 10px 30px;
	border-radius: 30px;
	transition: 0.4s linear;
}

.digital-calculator__options li:hover {
	cursor: pointer;
}

.digital-calculator__options .selected {
	background-color: #fce8ec;
}

.digital-calculator__figures {
	display: flex;
	justify-content: space-between;
}

.digital-calculator__figures__inputs {
	margin: auto 0;
}

.digital-calculator__figures__results-container {
	width: 340px;
	height: auto;
	background-color: #fafbfb;
	border: 1px solid #f19faf;
	border-radius: 20px;
	padding: 50px;
}

.digital-calculator__figures__results-container__fields div:not(:last-child) {
	border-bottom: 1px solid #e1e3e4;
}

@media screen and (max-width: 1100px) {
	.digital-calculator__interactive {
		margin: auto;
		max-width: 500px;
	}

	.digital-calculator__figures {
		flex-direction: column;
	}

	.digital-calculator__figures__results-container {
		margin-top: 40px;
	}

	.digital-calculator__figures__results-container button {
		width: 100%;
	}
}

@media screen and (max-width: 700px) {
	.digital-calculator__options li {
		padding: 10px;
	}
}

@media screen and (max-width: 500px) {
	.digital-calculator__options li {
		margin: 0;
		padding: 10px;
	}

	.digital-calculator__figures__results-container {
		width: unset;
		border-radius: 18px;
		padding: 30px;
	}
}
